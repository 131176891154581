<template>
  <div class="action-btn" v-bind="$attrs">
    <template v-if="btnList.length>3">
      <template v-for="index in [0,1]" :key="index">
        <a-tooltip class="tooltip-list" placement="top" color="#fff">
          <template #title>
            <span class="font-color">{{ btnList[index].name }}</span>
          </template>
          <a-button
              v-if="btnList[index].icon === 'SwapOutlined'"
              :class="($attrs.hardBook === '0' && $attrs.inProgress === '0')?'disabled':''"
              v-auth="btnList[index].auth"
              type="link"
              @click.stop="btnClick(btnList[index])"
          >
            <component :is="btnList[index].icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="btnList[index].icon === 'DownloadOutlined'"
              :class="$attrs.fileId === null ?'disabled':''"
              v-auth="btnList[index].auth"
              type="link"
              @click.stop="btnClick(btnList[index])"
          >
            <component :is="btnList[index].icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="btnList[index].icon === 'DeleteOutlined'"
              :class="$attrs.fileId === null ?'disabled':''"
              v-auth="btnList[index].auth"
              type="link"
              @click.stop="btnClick(btnList[index])"
          >
            <component :is="btnList[index].icon" :style="btnColorRed"/>
          </a-button>
          <a-button
              v-show="isShowOthers"
              v-else-if="btnList[index].icon === 'FormOutlined'"
              :class="$attrs.enabled === 0||$attrs.active_status==='0'||$attrs.status==='1'||($attrs.hardBook === 0 && $attrs.inProgress === 0)?'disabled':''"
              v-auth="btnList[index].auth"
              type="link"
              @click.stop="btnClick(btnList[index])"
          >
            <component :is="btnList[index].icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-show="isShowOthers"
              v-else-if="btnList[index].icon === 'MinusCircleOutlined'"
              :class="$attrs.status==='3'||$attrs.status==='4'?'disabled':''"
              v-auth="btnList[index].auth"
              type="link"
              @click.stop="btnClick(btnList[index])"
          >
            <component :is="btnList[index].icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else
              v-auth="btnList[index].auth"
              type="link"
              @click.stop="btnClick(btnList[index])"
          >
            <component :is="btnList[index].icon" :style="btnColorBlue"/>
          </a-button>
        </a-tooltip>
      </template>
      <div v-show="isShowOthers">
        <a-dropdown :trigger="trigger" :placement="placement">
        <a-button type="link" @click.prevent >
          <MoreOutlined :style=btnColorGray />
        </a-button>
        <template #overlay>
          <a-menu class="list-menu">
            <template v-for="(item, index) in btnList" :key="index">
              <a-menu-item v-if="index>1" class="list-item" :class="btnList[index].icon === 'SolutionOutlined'&&$attrs.jobStatus === '2'?'display_none': (btnList[index].icon === 'FormOutlined'?(($attrs.status === '3'||$attrs.status === '4')?'disabled':''): btnList[index].icon === 'HistoryOutlined'?(($attrs.status === '3'||$attrs.status === '4' || isHistoryOutlined($attrs.startDate,$attrs.endDate))?'disabled':''):'')" @click.stop="btnClick(btnList[index])">
                <component :class="btnList[index].icon" :is="btnList[index].icon" :style="btnColorBlue"/>
                <span class="span-list" :class="btnList[index].icon === 'FormOutlined'?(($attrs.status === '3'||$attrs.status === '4')?'disabled-name':''): btnList[index].icon === 'HistoryOutlined'?(($attrs.status === '3'||$attrs.status === '4' || isHistoryOutlined($attrs.startDate,$attrs.endDate))?'disabled-name':''):''">
                {{ item.name }}
              </span>
              </a-menu-item>
            </template>
          </a-menu>
        </template>
      </a-dropdown>
      </div>
    </template>
    <template v-else>
      <template v-for="(item, index) in btnList" :key="index">
        <a-tooltip class="tooltip-list" placement="top" color="#fff">
          <template #title>
            <span class="font-color">{{ item.name }}</span>
          </template>
          <a-button
              v-if="item.icon === 'SwapOutlined'"
              :class="($attrs.hardBook === '0' && $attrs.inProgress === '0')?'disabled':''"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="item.icon === 'DownloadOutlined'"
              :class="$attrs.fileId === null||['处理中','处理失败','已过期'].includes($attrs.status)?'disabled':''"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="item.icon === 'DeleteOutlined'"
              :class="$attrs.fileId === null ?'disabled':''"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorRed"/>
          </a-button>
          <a-button
              v-else-if="item.icon === 'FormOutlined'"
              :class="$attrs.enabled === 0||$attrs.active_status==='0'||$attrs.status==='1'||$attrs.status==='2'||($attrs.hardBook === 0 && $attrs.inProgress === 0)?'disabled':''"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="item.icon === 'SolutionOutlined'"
              :class="$attrs.jobStatus !== '2'?'display_none':''"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="item.icon === 'MinusCircleOutlined'"
              :class="$attrs.status==='1'||$attrs.status==='2'?'disabled':($attrs.enabled === 0?'display_none':'')"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="item.icon === 'PoweroffOutlined'"
              :class="$attrs.enabled === 1?'display_none':''"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else-if="item.icon === 'ExceptionOutlined'"
              :class="['处理中','处理完成','已过期'].includes($attrs.status)?'disabled':''"
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <component :is="item.icon" :style="btnColorBlue"/>
          </a-button>
          <a-button
              v-else
              v-auth="item.auth"
              type="link"
              @click.stop="btnClick(item)"
          >
            <div class="icon-info" v-if="item.icon ==='icon-info'"></div>
            <component v-else :is="item.icon" :style="btnColorBlue"/>
          </a-button>
        </a-tooltip>
      </template>
    </template>
  </div>
</template>
<script>

import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import {
  MoreOutlined,   //更多···
  SwapOutlined,   //PM变更
  HistoryOutlined,    //变更记录
  IdcardOutlined,   //个人信息
  UploadOutlined,   //上传
  DownloadOutlined,   //下载
  DeleteOutlined,   //删除
  FormOutlined,   //编辑
  MinusCircleOutlined,    //停用
  PoweroffOutlined,   //启用
  EyeOutlined,    //查看
  FileImageOutlined,    //预览
  AppstoreAddOutlined,    //任务列表
  SolutionOutlined,   //添加任务
  FileSyncOutlined,   //更新
  ExceptionOutlined,  //异常信息
} from '@ant-design/icons-vue';
import moment from "moment";
export default defineComponent ({
  name: "ActionBtn",
  components: {
    MoreOutlined,   //更多···
    SwapOutlined,   //PM变更
    HistoryOutlined,    //变更记录
    IdcardOutlined,   //个人信息
    UploadOutlined,   //上传
    DownloadOutlined,   //下载
    DeleteOutlined,   //删除
    FormOutlined,   //编辑
    MinusCircleOutlined,    //停用
    PoweroffOutlined,   //启用
    EyeOutlined,    //查看
    FileImageOutlined,    //预览
    AppstoreAddOutlined,    //任务列表
    SolutionOutlined,   //添加任务
    FileSyncOutlined,   //更新
    ExceptionOutlined,  //异常信息
  },
  props: {
    btnList: {
      type: Array,
      default: () => []
    },
    trigger: {
      type: Array,
      default: ()=>['click']
    },
    placement: {
      type: String,
      default: 'bottomRight'
    },
    recordData:{
      type: Object,
      default: () => {return {}}
    },
    userNameValue:{
      type:String,
      default:''
    },
  },
  emits: [
    'SwapOutlined',
    'HistoryOutlined',
    'IdcardOutlined',
    'UploadOutlined',
    'DownloadOutlined',
    'DeleteOutlined',
    'FormOutlined',
    'MinusCircleOutlined',
    'PoweroffOutlined',
    'EyeOutlined',
    'FileImageOutlined',
    'AppstoreAddOutlined',
    'SolutionOutlined',
    'FileSyncOutlined',
    'ExceptionOutlined',
  ],
  setup(props,cxt){
    const {emit, attrs} = cxt
    const route = useRoute()
    let isShowOthers = true
    if(route.path == '/my/application'){
      if(props.recordData.sqrName !== props.userNameValue && props.recordData.groupLeadName == props.userNameValue){
        isShowOthers = false
      }
      // 当前行Type为1，2，3的时候只能查看不能修改
      if(props.recordData && props.recordData.Type && ['1','2','3'].includes(props.recordData.Type)){
        isShowOthers = false
      }
      if(props.recordData.Type === '2' && props.recordData.sqrName === props.userNameValue){
        isShowOthers = true
      }
    }
    const btnClick = (data)=> {
      const {icon} = data
      if(icon === 'SwapOutlined' && (attrs.hardBook === '0' && attrs.inProgress === '0')) {
        return false
      } else if(icon === 'DownloadOutlined' && (attrs.fileId === null || ['处理中','处理失败','已过期'].includes(attrs.status))){
        return false
      } else if(icon === 'FormOutlined' && (attrs.groupLead? (attrs.status === '3'||attrs.status === '4') : (attrs.enabled === 0||attrs.active_status ==='0'||attrs.status==='1'||attrs.status==='2'||(attrs.hardBook === 0 && attrs.inProgress === 0))) ){
        return false
      } else if(icon === 'MinusCircleOutlined' && (attrs.groupLead? (attrs.status === '3'||attrs.status === '4') : (attrs.status==='1'||attrs.status==='2'||attrs.enabled === 0)) ){
        return false
      } else if(icon === 'DeleteOutlined' && (attrs.fileId === null) ){
        return false
      } else if(icon === 'HistoryOutlined' && ((attrs.status === '3'||attrs.status === '4') || !(moment(attrs.startDate).diff(moment().format("YYYY-MM-DD"),'days')<=0 && moment(attrs.endDate).diff(moment().format("YYYY-MM-DD"),'days')>=0)) ){
        return false
      } else if(icon === 'ExceptionOutlined' && ['处理中','处理完成','已过期'].includes(attrs.status) ){
        return false
      } else {
        emit(icon)
      }
    }
    const isHistoryOutlined = (startDate,endDate) => {
      if(moment(startDate).diff(moment().format("YYYY-MM-DD"),'days')<=0 && moment(endDate).diff(moment().format("YYYY-MM-DD"),'days')>=0){
        return false
      } else {
        return true
      }
    }
    return {
      /*btnList: [
        {
          name: 'PM变更',
          icon: 'SwapOutlined',
          auth: '',
        },
        {
          name: '变更记录',
          icon: 'HistoryOutlined',
          auth: '',
        },
        {
          name: '个人信息',
          icon: 'IdcardOutlined',
          auth: '',
        },
        {
          name: '更新',
          icon: 'FileSyncOutlined',
          auth: '',
        },
        {
          name: '详情信息',
          icon: 'icon-info',
          auth: '',
        },
      ],*/
      btnColorBlue: {color: '#3182CE'},
      btnColorRed: {color: '#DC5E4F'},
      btnColorGray: {color: '#999'},
      btnClick,
      isHistoryOutlined,
      isShowOthers
    }
  }
});
</script>

<style lang="less" scoped>
.action-btn{
  display: flex;
}
:deep(.tooltip-list){
  display: flex;
  align-items: center;
}
:deep(.ant-btn){
  padding: 0;
  height: auto;
  &:not(:last-child){
    margin-right: 12px;
  }
  .anticon{
    display: block;
    width: 24px;
    svg{
      width: 100%;
      height: 100%;
    }
    &.anticon-delete{
      svg{
        color: #DC5E4F;
      }
    }
    &.anticon-minus-circle{
      svg{
        color: #DC5E4F;
      }
    }
  }
}
:deep(.disabled){
  cursor: auto;
  .anticon{
    color: #999 !important;
    svg{
      color: #999 !important;
    }
  }
}
:deep(.display_none){
  display: none;
}
.list-menu{
  width: 130px;
  :deep(svg){
    width: 18px;
    height: 18px;
  }
}
:deep(.ant-dropdown-menu-title-content){
  display: flex;
  align-items: center;
}
.span-list{
  color: #3182CE;
  line-height: 26px;
  .antion{
    width: 18px;
    height: 18px;
  }
}
.icon-info{
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("../assets/images/icon_info.png") 0 50% no-repeat;
  background-size: 24px 24px;
}
.font-color{
  color:#4d4d4d;
}
.disabled-name {
  color: #999
}
</style>
